html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
#root {
  height: 100%;
  width: 100%;
  display: flex;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
